/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"
import React from 'react';
import AppProvider from "./src/providers/app-provider";
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

// Wraps every page in a component
export function wrapPageElement({ element, props }) {

    return (
            <AppProvider>{element}</AppProvider>
    )
  }

