import React, { ReactElement, useState } from "react"
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';

export default function AppProvider(props: {children: ReactElement}) {
  const { children } = props;
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUB_KEY);

  let client = new ApolloClient({
    cache: new InMemoryCache()
  });


  if (typeof fetch !== 'undefined') {
    client = new ApolloClient({
        cache: new InMemoryCache(),
        link: new HttpLink({
            uri: process.env.GATSBY_GRAPHQL_HOST,
            fetch
        })
    });
  }

  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>{ children }</Elements>
    </ApolloProvider>
  )
}